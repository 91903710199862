import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./pages/index.vue')
      // meta: { requiresAuth: false }
    },
    {
      path: '/create-shipment',
      name: 'create-shipment',
      component: () => import('./pages/create-shipment.vue')
      // meta: { requiresAuth: true }
    }
  ]
})
// appRouter.beforeEach((to, from, next) => {
//   // Your navigation logic here
//
//   function isAuthenticated () {
//     return false
//   }
//
//   // Example: Authenticate before accessing admin routes
//   if (!isAuthenticated()) {
//     next('/login')
//   } else {
//     next()
//   }
// })
// router.beforeEach(async (to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     const auth = false
//     if (auth) { // await Auth.isAuthenticated()
//       next()
//     } else {
//       next({ name: 'Login' }) // Redirect to login if not authenticated
//     }
//   } else {
//     next() // Allow navigation for non-protected routes
//   }
// })
export default router
