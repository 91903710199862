<template>
  <v-app style="font-family: 'Poppins', sans-serif;">
    <v-main class="!tw-p-0">
      <v-card
        class="mx-auto overflow-hidden tw-bg-[#f6f8fb]"
        min-height="100vh"
      >
        <v-app id="inspire">
          <v-app-bar app color="#fff" flat class="tw-px-10">
            SHIPMENTS
          </v-app-bar>

          <v-navigation-drawer
            color="#fff"
            permanent
            app
            class="tw-pt-[1rem]"
            width="200px"
          >
            <v-list nav dense>
<!--              <v-list-item link href="#" class="tw-items-center tw-flex !tw-mb-6">-->
<!--                <v-list-item-icon class="tw-h-auto">-->
<!--                  <v-avatar class="tw-bg-[#3a6cfa]/10">-->
<!--                    <v-icon color="#3a6cfa" small class="las la-braille !tw-text-[25px]"></v-icon>-->
<!--                  </v-avatar>-->
<!--                </v-list-item-icon>-->
<!--                <v-list-item-title class="tw-text-[#272727] !tw-text-[16px] tw-pb-1">Dashboard</v-list-item-title>-->
<!--              </v-list-item>-->
<!--              <v-list-item link href="#" class="tw-items-center tw-flex !tw-mb-6">-->
<!--                <v-list-item-icon class="tw-h-auto">-->
<!--                  <v-avatar class="tw-bg-[#11c5f3]/10">-->
<!--                    <v-icon color="#11c5f3" small class="las la-podcast !tw-text-[25px]"></v-icon>-->
<!--                  </v-avatar>-->
<!--                </v-list-item-icon>-->
<!--                <v-list-item-title class="tw-text-[#272727] !tw-text-[16px] tw-pb-1">Purchasing</v-list-item-title>-->
<!--              </v-list-item>-->
              <v-list-item link href="/" class="tw-items-center tw-flex !tw-mb-6">
                <v-list-item-icon class="!tw-h-auto">
                  <v-avatar class="tw-bg-[#ffa600]/10">
                    <v-icon color="#ffa600" small class="las la-shipping-fast !tw-text-[25px]"></v-icon>
                  </v-avatar>
                </v-list-item-icon>
                <v-list-item-title class="tw-text-[#272727] !tw-text-[16px] tw-pb-1">Shipping</v-list-item-title>
              </v-list-item>
<!--              <v-list-item link href="#" class="tw-items-center tw-flex !tw-mb-6">-->
<!--                <v-list-item-icon class="tw-h-auto">-->
<!--                  <v-avatar class="tw-bg-[#68d547]/10">-->
<!--                    <v-icon color="#68d547" small class="las la-chart-bar !tw-text-[25px]"></v-icon>-->
<!--                  </v-avatar>-->
<!--                </v-list-item-icon>-->
<!--                <v-list-item-title class="tw-text-[#272727] !tw-text-[16px] tw-pb-1">Analytics</v-list-item-title>-->
<!--              </v-list-item>-->
              <v-list-item link href="#" class="tw-items-center tw-flex !tw-mb-6 !tw-min-h-[auto]">
                <v-list-item-icon class="!tw-h-auto">
                  <v-avatar class="tw-bg-[#e81d25]/10">
                    <v-icon color="#e81d25" small class="las la-stream !tw-text-[25px]"></v-icon>
                  </v-avatar>
                </v-list-item-icon>
                <v-list-item-title class="tw-text-[#272727] !tw-text-[16px] tw-pb-1">Settings</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
          <v-content>
            <v-card-text>
              <router-view/>
            </v-card-text>
          </v-content>
        </v-app>
<!--       <div class="tw-border-b !tw-border-[#f1f1f1]">-->
<!--         <v-container>-->
<!--           <v-toolbar-title>Shipment Yard</v-toolbar-title>-->
<!--         </v-container>-->
<!--       </div>-->
      </v-card>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    group: null
  })
}
</script>
