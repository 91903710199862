import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)
const vuetify = new Vuetify({
  theme: {
    defaults: {
      body: {
        // fontFamily: 'Raleway'
        fontFamily: '"Poppins", sans-serif'
      }
    },
    themes: {
      light: {
        primary: '#3a6dff',
        secondary: '#1f567b',
        accent: '#82B1FF'
      }
    }
  },
  defaults: {
    body: {
      // fontFamily: 'Raleway'
      fontFamily: '"Poppins", sans-serif'

    }
  },
  icons: {
    iconfont: 'mdi' // Set icon font
  }
})

Vue.use(vuetify)

export default vuetify
