import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import VuexPersist from 'vuex-persist'
import { products } from '@/store/modules/products'

Vue.use(Vuex)

const vuexPersist = new VuexPersist({
  key: 'app',
  storage: window.localStorage
})
export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: { products },
  plugins: [vuexPersist.plugin]
  // strict: process.env.NODE_ENV !== "production",
})
